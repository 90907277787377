//对axios请求进行封装
import axios from "axios"
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
import router from "@/router"
//创建一个新的axios对象并进行初始化设置

const http=axios.create({   
   baseURL:'http://47.122.21.140:8000/api/', //通用请求前缀，生产环境
   // baseURL:'http://10.41.63.254:8000/api/',
  //baseURL:'http://localhost:8000/api/',  //调试环境通过npm run serve -- --port 8080指定端口
    //-------------------------------------------------------------------
    //记得同步改变websocket地址
    //--------------------------------------------------------------  
    timeout:50000,//超时10S 
    // headers: {                         // 请求头
    //     "Content-Type": "application/json",      
    //   },
})
//添加请求拦截器,可以添加token(JWT)校验
http.interceptors.request.use(    
  config=>{   
   //const token=localStorage.getItem('token')  
   const token=Cookie.get('token')
   if(token){     
      config.headers.Authorization=`Bearer ${token}`       
   } else{    
    //跳转到登录界面
    router.push('/logoin')
   }   
   return config; 
  },
  error=>{
    console.log('请求拦截器出错了')
    return Promise.reject(error)
  }
  )

  //添加响应拦截器，统一处理错误
 // 添加响应拦截器
 http.interceptors.response.use(
    response => {
      // 对于 HTTP 状态码非 200 的响应，将错误信息以 Promise.reject 的形式返回
      if (response.status !== 200) {
        //console.log(response)
        return Promise.reject(response);
      }
      return response;
    },
    error => {
      // 对于 401 错误，自动跳转到登录页面
      console.log('错误内容：'+error.response)
      if (error.response && error.response.status === 401) {
        console.log('检测到401错误，没有取得授权')
        Cookie.remove('token')
        alert('没有授权或已过期，请重新登录！')
        router.push('/logoin')
        return        
      }     
    }
  );
export default http;

