import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
Vue.use(VueRouter)
// 解决导航重复执行同一个导航地址报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}
const routes = [
  { //配置主路由
    path:'/',   //主路由显示在app.vue中的路由出口中
    component:Main,//这里的名字是导入时的名称
    redirect:'/home',//重定向到home下
    children:[ //实现嵌套路由的子路由
      {   //子路由需要在父路由中进行显示，显示在main的内部
        path: 'home',
        name: 'home',
        component: Home   //首页
      },{
        path: 'unitadmin',
        name: 'unitadmin',        
        component: () => import('../views/sysadmin/UnitAdmin.vue') //单位管理员
      },{
        path:'unit',
        name:'unit',
        component: () => import('../views/sysadmin/Unit.vue') //单位设置
      },{
        path:'unitsensortype',
        name:'unitsensortype',
        component: () => import('../views/sysadmin/Unitsensortype.vue') //传感器种类设置
      },{
        path:'sensor_typedetail',
        name:'sensor_typedetail',
        component: () => import('../views/sysadmin/sensor_typedetail.vue') //小程序分类设置
      },{
        path:'sensoricons',
        name:'sensoricons',
        component: () => import('../views/sysadmin/sensoricons.vue') //小程序图标设置
      },{
        path:'menu_access',
        name:'menu_access',
        component: () => import('../views/sysadmin/menu_access.vue') //分组名称设置
      },{
        path:'groupname',
        name:'groupname',
        component: () => import('../views/sysadmin/groupname.vue') //分组名称设置
      },{
        path:'type',
        name:'type',
        component: () => import('../views/sysadmin/type.vue') //设备类型设置
      }, {
        path:'arm',
        name:'arm',
        component: () => import('../views/sysadmin/Arm.vue') //ARM设置
      },{
        path:'Armupdate',
        name:'Armupdate',
        component: () => import('../views/sysadmin/Armupdate.vue') //ARM设置
      },{
        path:'machinesetup',
        name:'machinesetup',
        component: () => import('../views/machine/machinesetup.vue') //单位用户的机器设置
      },{
        path:'sensorsetup',
        name:'sensorsetup',
        component: () => import('../views/machine/sensorsetup.vue') //采用延迟加载
      },{
        path:'sensordeitail',
        name:'sensordeitail',
        component: () => import('../views/machine/sensordeitail.vue') //采用延迟加载
      },{ //可以控制设置和状态显示       
        path:'sensordetailstate',
        name:'sensordetailstate',
        component: () => import('../views/machine/sensordetailstate.vue') 
      },{
        path:'IRsetup',
        name:'IRsetup',
        component: () => import('../views/machine/IRsetup.vue') //采用延迟加载
      },{
        path:'sensoralarm',
        name:'sensoralarm',
        component: () => import('../views/alarm/sensoralarm.vue') //采用延迟加载
      },{
        path:'sensoralarmtarget',
        name:'sensoralarmtarget',
        component: () => import('../views/alarm/sensoralarmtarget.vue') //采用延迟加载
      },{
        path:'alarm_record',
        name:'alarm_record',
        component: () => import('../views/alarm/alarm_record.vue') //采用延迟加载
      },{
        path:'alarm_bossview',
        name:'alarm_bossview',
        component: () => import('../views/alarm/alarm_bossview.vue') //采用延迟加载
      },{
        path:'sensoraccess',
        name:'sensoraccess',
        component: () => import('../views/machine/sensoraccess.vue') //采用延迟加载
      },{
        path:'autocheck',
        name:'autocheck',
        component: () => import('../views/machine/autocheck.vue') //采用延迟加载
      },{
        path:'autocontrol',
        name:'autocontrol',
        component: () => import('../views/machine/autocontrol.vue') //采用延迟加载
      },
      { //时间自动控制方案门禁
        path:'timeautocontrol_door',
        name:'timeautocontrol_door',
        component: () => import('../views/autocontrol/timeautocontrol_door.vue') //采用延迟加载
      },
      { //时间控制门禁的目标明细
        path:'door_autocontrol_target',
        name:'door_autocontrol_target',
        component: () => import('../views/autocontrol/door_autocontrol_target.vue') //采用延迟加载
      },
      { //时间自动控制方案，电力
        path:'timeautocontrol_sensor',
        name:'timeautocontrol_sensor',
        component: () => import('../views/autocontrol/timeautocontrol_sensor.vue') //采用延迟加载
      },//
      { //时间控制门禁的目标明细
        path:'sensor_autocontrol_target',
        name:'sensor_autocontrol_target',
        component: () => import('../views/autocontrol/sensor_autocontrol_target') //采用延迟加载
      },
      
      {
        path:'onekeynet',
        name:'onekeynet',
        component: () => import('../views/machine/onekeynet.vue') //采用延迟加载
      },{
        path:'lineecharts',
        name:'lineecharts',
        component: () => import('../views/data/lineecharts.vue') //采用延迟加载
      },{
        path:'userinfor',
        name:'userinfor',
        component: () => import('../views/Userinfor.vue') //采用延迟加载
      },{
        path:'editpassword',
        name:'editpassword',
        component: () => import('../views/Editpassword.vue') //采用延迟加载
      },{
        path:'doorsetup',
        name:'doorsetup',
        component: () => import('../views/door/doorsetup.vue') //采用延迟加载
      },{
        path:'doorrecord',
        name:'doorrecord',
        component: () => import('../views/door/doorrecord.vue') //采用延迟加载
      },{
        path:'dooroperation',
        name:'dooroperation',
        component: () => import('../views/door/dooroperation.vue') //采用延迟加载
      },{
        path:'dooraccess',
        name:'dooraccess',
        component:()=>import('../views/door/dooraccess.vue')
      },{
        path:'dooraccess_man',
        name:'dooraccess_man',
        component:()=>import('../views/door/dooraccess_man.vue')
      },{
        path:'doorstate',
        name:'doorstate',
        component:()=>import('../views/door/doorstate.vue')
      },{        
        path:'dooralarm',
        name:'dooralarm',
        component:()=>import('../views/alarm/dooralarm.vue')
      },{        
        path:'dooralarmtarget',
        name:'dooralarmtarget',
        component:()=>import('../views/alarm/dooralarmtarget.vue')
      },{
        path:'meet',
        name:'meet',
        component:()=>import('../views/meet/meet.vue')
      },{
        path:'meetcontrol',
        name:'meetcontrol',
        component:()=>import('../views/meet/meetcontrol.vue')
      },{
        path:'meetserver',
        name:'meetserver',
        component:()=>import('../views/meet/meetserver.vue')
      },{
        path:'meetserverbindman',
        name:'meetserverbindman',
        component:()=>import('../views/meet/meetserverbindman.vue')
      },{
        path:'meetapplay',
        name:'meetapplay',
        component:()=>import('../views/meet/meetapplay.vue')
      },{
        path:'roadledsetup',
        name:'roadledsetup',
        component:()=>import('../views/roadled/roadledsetup.vue')
      },{
        path:'roadledcontrol',
        name:'roadledcontrol',
        component:()=>import('../views/roadled/roadledcontrol.vue')
      },{
        path:'autocontroldetail',
        name:'autocontroldetail',
        component:()=>import('../views/roadled/autocontroldetail.vue')
      },{
        path:'roadledcontrolrecord',
        name:'roadledcontrolrecord',
        component:()=>import('../views/roadled/roadledcontrolrecord.vue')
      },{        
        path:'roadledpolice',
        name:'roadledpolice',
        component:()=>import('../views/roadled/roadledpolice.vue')
      },{        
        path:'roadledbossview',
        name:'roadledbossview',
        component:()=>import('../views/roadled/roadledbossview.vue')
      },{        
        path:'roadledmapview',
        name:'roadledmapview',
        component:()=>import('../views/roadled/roadledmapview.vue')
      },{        
        path:'publicmessage',
        name:'publicmessage',
        component:()=>import('../views/publicmessage/publicmessage.vue')
      },{        
        path:'template',
        name:'template',
        component:()=>import('../views/publicmessage/template.vue')
      },{        
        path:'receive',
        name:'receive',
        component:()=>import('../views/publicmessage/receive.vue')
      },{//用水实时参数        
        path:'waterflash',
        name:'waterflash',
        component:()=>import('../views/energy/waterflash.vue')
      },{//用电实时参数        
        path:'powerflash',
        name:'powerflash',
        component:()=>import('../views/energy/powerflash.vue')
      },{//用电参数        
        path:'UIP',
        name:'UIP',
        component:()=>import('../views/energy/UIP.vue')
      },{//用水统计        
        path:'water',
        name:'water',
        component:()=>import('../views/energy/water.vue')
      },{//用电统计        
        path:'kwh',
        name:'kwh',
        component:()=>import('../views/energy/kwh.vue')
      },{//用气统计        
        path:'gas',
        name:'gas',
        component:()=>import('../views/energy/gas.vue')
      },{//环境监测    
        path:'environment',
        name:'environment',
        component:()=>import('../views/energy/environment.vue')
      },{        
        path:'bullyingsetup',
        name:'bullyingsetup',
        component:()=>import('../views/bullying/bullyingsetup.vue')
      },{        
        path:'bullying_tel',
        name:'bullying_tel',
        component:()=>import('../views/bullying/bullying_tel.vue')
      },{        
        path:'bullying_state',
        name:'bullying_state',
        component:()=>import('../views/bullying/bullying_state.vue')
      },

    ]
  },{
    path:'/login',
    name:'login',
    component:Login,
  }
]
const router = new VueRouter({
  routes
})

export default router
