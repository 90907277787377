<template>

<el-menu  default-active="1-4-1"  class="el-menu-vertical-demo"  @open="handleOpen" 
 @close="handleClose"  :collapse="isCollapse" :unique-opened="true"
background-color="#545c64"      text-color="#fff"      active-text-color="#ffd04b">
<!-- 是否折叠，若折叠则显示后台，否则显示全部 -->
<h3>{{isCollapse ? '后台':userinfor.showname}}</h3>
    <!-- 这是没有子菜单的,index是唯一标识 -->
    <el-menu-item  class="menuitem" @click="clickmenu(item)"  v-for="item in noChildren" :key="item.name" :index="item.name">
      <!-- <i class="el-icon-menu"></i>  显示图标前面el-icon是固定的,``为es6的模版字符串语法,动态数据${}-->
      <i  :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>    
    <!-- 这是有子菜单的 -->
    <el-submenu  v-for="item in hasChildren" :key="item.label" :index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <!-- slot为插槽 -->
        <span  slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group  class="menuitem" v-for="subItem in item.children" key:="subItem.path">
        <!-- 注意事件写在menu item中 -->
            <el-menu-item  @click="clickmenu(subItem)"  :index="subItem.path">{{ subItem.label }}</el-menu-item>        
      </el-menu-item-group>      
    </el-submenu>
  </el-menu>
</template>


<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import http from "@/tools/request"
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
export default {
  data() {
    return {
     // isCollapse: false,//是否水平折叠收起菜单，默认为false
     //在计算属性中已定义了，不能重复定义，所以注释掉
     userinfor:{
        unitid:0,
        ID:0
      },
     menudata:[
      
        
       
      ],//这是数组的最外侧括号
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
     // console.log(key, keyPath);
    },    
    clickmenu(item){ //点击菜单的事件，需要传值
      //解决连续两次点击同一个菜单出错的情况,三等于号为一致
       if(this.$route.path!==item.path&&!(this.$route.path==='/home'&& item.path==='/')){
        this.$router.push(item.path)
       }
       //检查面包屑数组，没有则增加一个
       this.$store.commit('selectMenu',item) //第一个参数为事件，第二个为参数
    }

  },
  computed:{//通过计算属性来过滤,把menudata的数据转换后输出
     noChildren(){ //如果无子菜单则为假取反为真直接返回
        return this.menudata.filter(item=>!item.children)
     },
     hasChildren(){ //有子菜单的才返回
        return this.menudata.filter(item=>item.children)
     },
     isCollapse(){ //计算属性中定义一个方法返回变量的值
       return this.$store.state.tab.isCollapse
       //return false
     }
  },
  mounted() {
        const token= Cookie.get('token')    
        if(token){
          this.userinfor.unitid=Cookie.get('unitid')
          this.userinfor.ID=Cookie.get('ID')     
          try{
            http.post("base_menu/getmenuinfor",this.userinfor) 
              .then((res) => {          
               // console.log(res.data.data)
                this.menudata=res.data.data                
              })
            }catch(e){
              console.error('Error:',e)
            }      
        }       
     },

};
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu{
    border-right: none;//去掉右侧的一根线
    height:100vh;  /* 高度为100% */
    min-height: 580px;
    h3{
      color: #fff;
      text-align: center;
      line-height: 48px;
      // 字体的大小和字体的粗细
      font-size: 16px;font-weight: 400;     
    }

}
</style>